
.login_container {
    position: relative;
    height: 100vh;
    margin: 0;
    padding: 0;
    background: #ffffff;
    background: var(--common_bg_z1_color, #ffffff);

    .page-wrapper {
        overflow: hidden;
        position: relative;
        width: 100%;
        height: 100%;
        min-height: 570px;
        margin: 0;
        padding: 0;
        background: #ffffff;
        background: var(--common_bg_z1_color, #ffffff);
        opacity: 0.01;
        transition: opacity 0.3s;
    }

    .page-wrapper.page-wrapper-visible {
        opacity: 1;
    }

    .page-wrapper .banner-area {
        display: none;
        position: absolute;
        width: 500px;
        left: 0;
        top: 0;
        bottom: 0;
        background-size: cover !important;
        background-position: center center;
    }

    .page-wrapper .banner-area .banner-link {
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    .page-wrapper .app-area {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    .page-wrapper .app-box {
        position: absolute;
        background: #ffffff;
        background: var(--common_bg_z1_color, #ffffff);
    }

    .page-wrapper .app-loading {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(255, 255, 255, 0.6);
        opacity: 0;
        transition: all 0.3s;
    }

    .page-wrapper .app-loading .app-loading-bar {
        overflow: hidden;
        position: absolute;
        height: 4px;
        top: 0;
        left: 0;
        right: 0;
        background: #f2f2f6;
        background: var(--common_bg_color, #f2f2f6);
    }

    @keyframes loadingbar {
        to {
            transform: translateX(300%);
        }
    }

    .page-wrapper .app-loading .app-loading-bar:after {
        content: '';
        display: block;
        position: absolute;
        width: 50%;
        height: 100%;
        right: 100%;
        top: 0;
        background: #0089ff;
        background: var(--theme_primary1_color, #0089ff);
        animation: loadingbar 1s linear infinite;
    }

    .page-wrapper .app-loading.app-loading-show {
        z-index: 1000;
        opacity: 1;
    }

    .page-wrapper .app-toast {
        position: absolute;
        z-index: 100;
        top: 20px;
        left: 60px;
        right: 60px;
        text-align: center;
        opacity: 0;
        transform: translateY(-80px);
        transition: all 0.3s;
    }

    .page-wrapper .app-toast.app-toast-show {
        opacity: 1;
        transform: translateY(0);
    }

    .page-wrapper .app-page {
        overflow: hidden;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        transition: all 0.3s;
        transform: translateX(0);
    }

    .page-wrapper .app-page.app-page-curr {
        z-index: 10;
        opacity: 1;
        transform: translateX(0) !important;
    }

    .page-wrapper .app-page.app-page-prev {
        z-index: 1;
        opacity: 0;
        transform: translateX(-50px) !important;
    }

    .page-wrapper .app-page.app-page-next {
        z-index: 1;
        opacity: 0;
        transform: translateX(50px) !important;
    }

    .page-wrapper .app-page-bg {
        overflow: hidden;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    .page-wrapper .app-page-bg div {
        display: none;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-size: 100% auto !important;
        background-position: 50% 0% !important;
        background-repeat: no-repeat !important;
    }

    .page-wrapper .app-scroll {
        overflow-x: hidden;
        overflow-y: hidden;
        position: absolute;
        z-index: 1;
        left: 0;
        top: 0;
        bottom: 0;
    }

    .page-wrapper .app-scroll-content {
        position: relative;
    }

    .page-wrapper .app-content {
        position: relative;
        z-index: 1;
        max-width: 360px;
        margin: 0 auto;
    }

    .page-wrapper .app-content-bg {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    .page-wrapper .app-login-switcher {
        position: absolute;
        z-index: 20;
        top: 0;
        right: 0;
        width: 80px;
        height: 80px;
    }

    .page-wrapper .module-comm-page-back-btn {
        display: block;
        position: absolute;
        width: 70px;
        height: 60px;
        top: -60px;
        left: -16px;
        padding-left: 16px;
        line-height: 60px;
        font-size: 14px;
        color: rgba(23, 26, 29, 0.6);
        color: var(--common_level2_base_color, rgba(23, 26, 29, 0.6));
        text-align: left;
        cursor: pointer;
    }

    .page-wrapper .module-comm-page-back-btn:hover {
        color: #0089ff;
        color: var(--theme_primary1_color, #0089ff);
    }

    .page-wrapper .module-comm-page-back-btn i {
        display: block;
        position: absolute;
        width: 16px;
        height: 20px;
        left: 0;
        top: 50%;
        margin-top: -10px;
        line-height: 20px;
        font-size: 16px;
        text-align: left;
    }

    .page-wrapper .module-comm-page-title {
        display: block;
        margin: 0 auto 10px;
        line-height: 35px;
        font-size: 24px;
        color: #171a1d;
        color: var(--common_level1_base_color, #171a1d);
        text-align: left;
        font-weight: bold;
    }

    .page-wrapper .module-comm-page-desc {
        display: block;
        margin: 0 auto;
        line-height: 25px;
        font-size: 14px;
        color: rgba(23, 26, 29, 0.6);
        color: var(--common_level2_base_color, rgba(23, 26, 29, 0.6));
        text-align: left;
    }

    .page-wrapper .module-comm-page-desc span {
        color: #0089ff;
        color: var(--theme_primary1_color, #0089ff);
        cursor: pointer;
    }

    .page-wrapper .module-comm-page-desc span:hover {
        opacity: 0.9;
    }

    .app-page-fullview .app-box,
    .app-page-iframeview .app-box {
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    .app-page-fullview .app-page,
    .app-page-iframeview .app-page {
        padding: 70px 32px;
    }

    .app-page-fullview .app-scroll,
    .app-page-iframeview .app-scroll {
        right: 0;
    }

    .app-page-fullview .app-scroll-content,
    .app-page-iframeview .app-scroll-content {
        width: 100%;
        padding: 70px 32px;
    }

    .app-page-iframeview {
        min-height: 280px;
    }

    .app-page-iframeview .app-page,
    .app-page-iframeview .app-scroll-content {
        padding: 0;
    }

    .app-page-bannerview .app-box,
    .app-page-boxview .app-box {
        width: 480px;
        height: 600px;
        left: 50%;
        top: 50%;
        margin-left: -240px;
        margin-top: -320px;
        border-radius: 10px;
        border: 1px solid rgba(126, 134, 142, 0.16);
        border: 1px solid var(--common_line_light_color, rgba(126, 134, 142, 0.16));
        box-shadow: 0 4px 14px 0 rgba(126, 134, 142, 0.16);
        box-shadow: 0 4px 14px 0 var(--common_line_light_color, rgba(126, 134, 142, 0.16));
    }

    .app-page-bannerview .app-page,
    .app-page-boxview .app-page {
        padding: 70px 80px;
    }

    .app-page-bannerview .app-page-bg,
    .app-page-boxview .app-page-bg {
        border-radius: 10px;
    }

    .app-page-bannerview .app-scroll,
    .app-page-boxview .app-scroll {
        right: -100px;
    }

    .app-page-bannerview .app-scroll-content,
    .app-page-boxview .app-scroll-content {
        width: 480px;
        padding: 70px 80px;
    }

    .app-page-bannerview .app-cpright-bar,
    .app-page-boxview .app-cpright-bar {
        position: absolute;
        width: 480px;
        height: 60px;
        bottom: 0;
        left: 50%;
        margin-left: -240px;
        line-height: 60px;
        font-size: 12px;
        color: rgba(23, 26, 29, 0.6);
        color: var(--common_level2_base_color, rgba(23, 26, 29, 0.6));
        text-align: left;
    }

    .app-page-bannerview .app-cpright-bar span,
    .app-page-boxview .app-cpright-bar span {
        margin: 0 7px;
        cursor: pointer;
    }

    .app-page-bannerview .app-cpright-bar span:hover,
    .app-page-boxview .app-cpright-bar span:hover {
        color: #0089ff;
        color: var(--theme_primary1_color, #0089ff);
    }

    .app-page-bannerview .app-cpright-bar .app-cpright,
    .app-page-boxview .app-cpright-bar .app-cpright {
        float: left;
        padding: 0 7px;
    }

    .app-page-bannerview .app-cpright-bar .app-privacy,
    .app-page-boxview .app-cpright-bar .app-privacy {
        float: right;
    }

    .app-page-bannerview .module-comm-page-back-btn,
    .app-page-boxview .module-comm-page-back-btn {
        margin-left: -50px;
    }

    .app-page-bannerview .banner-area {
        display: block;
    }

    .app-page-bannerview .app-area {
        left: 500px;
    }

    .app-page-boxbg-pc .app-page-bg-pc,
    .app-page-boxbg-mob .app-page-bg-mob {
        display: block !important;
    }


    .module-agreement {
        overflow: hidden;
        position: absolute;
        top: 0;
        bottom: 16px;
        left: 16px;
        right: 16px;
    }

    .module-agreement .module-agreement-content-area {
        overflow: hidden;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 100px;
    }

    .module-agreement .module-agreement-content-area .module-agreement-content-area-inner {
        position: absolute;
        left: 0;
        right: -17px;
        top: 0;
        bottom: 0;
        overflow-x: hidden;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
    }

    .module-agreement .module-agreement-content-area .module-agreement-content-area-inner iframe {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
    }

    .module-agreement .module-agreement-op-area {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 48px;
        padding: 16px 0 16px 22px;
        background: #ffffff;
        background: var(--common_bg_z1_color, #ffffff);
    }

    .module-agreement .module-agreement-op-area .module-agreement-agree-box {
        box-sizing: border-box;
        position: absolute;
        width: 14px;
        height: 14px;
        left: 0;
        top: 19px;
        line-height: 14px;
        font-size: 16px;
        color: rgba(23, 26, 29, 0.6);
        color: var(--common_level2_base_color, rgba(23, 26, 29, 0.6));
        text-align: center;
    }

    .module-agreement .module-agreement-op-area .module-agreement-agree-box.active {
        color: #0089ff;
        color: var(--theme_primary1_color, #0089ff);
    }

    .module-agreement .module-agreement-op-area .module-agreement-agree-desc {
        display: block;
        position: relative;
        line-height: 20px;
        font-size: 14px;
        color: rgba(23, 26, 29, 0.6);
        color: var(--common_level2_base_color, rgba(23, 26, 29, 0.6));
    }

    .module-agreement .module-agreement-op-area .module-agreement-agree-desc span {
        color: #0089ff;
        color: var(--theme_primary1_color, #0089ff);
        cursor: pointer;
    }

    .module-agreement .module-agreement-agree-button-area {
        position: absolute;
        height: 48px;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .module-agreement .module-agreement-agree-button-area .module-agreement-button {
        width: 48%;
    }

    .module-agreement .module-agreement-agree-button-area .module-agreement-button.module-agreement-button-cancel {
        float: left;
    }

    .module-agreement .module-agreement-agree-button-area .module-agreement-button.module-agreement-button-confirm {
        float: right;
    }

    .app-page-boxbg-mob .module-agreement-content-area-inner {
        right: 0 !important;
        overflow-y: auto !important;
    }

    .base-comp-button {
        box-sizing: border-box;
        position: relative;
        height: 48px;
        line-height: 48px;
        font-size: 16px;
        text-align: center;
        border-radius: 8px;
        cursor: pointer;
    }

    .base-comp-button-type-default {
        color: #171a1d;
        color: var(--common_level1_base_color, #171a1d);
        background: #FFFFFF;
        background: var(--button_secondary_bg_color, #FFFFFF);
        border: 1px solid rgba(126, 134, 142, 0.16);
        border: 1px solid var(--common_line_light_color, rgba(126, 134, 142, 0.16));
    }

    .base-comp-button-type-default:hover {
        color: #171a1d;
        color: var(--common_level1_base_color, #171a1d);
        background: #f2f2f6;
        background: var(--common_bg_color, #f2f2f6);
        border: 1px solid rgba(126, 134, 142, 0.16);
        border: 1px solid var(--common_line_light_color, rgba(126, 134, 142, 0.16));
    }

    .base-comp-button-type-default.base-comp-button-disabled {
        color: rgba(23, 26, 29, 0.24);
        color: var(--common_level4_base_color, rgba(23, 26, 29, 0.24));
        background: #FFFFFF;
        background: var(--button_secondary_bg_color, #FFFFFF);
        border: 1px solid rgba(126, 134, 142, 0.16);
        border: 1px solid var(--common_line_light_color, rgba(126, 134, 142, 0.16));
    }

    .base-comp-button-type-primary {
        color: #ffffff;
        color: var(--common_white1_color, #ffffff);
        background: #0089ff;
        background: var(--theme_primary1_color, #0089ff);
    }

    .base-comp-button-type-primary:hover {
        color: #ffffff;
        color: var(--common_white1_color, #ffffff);
        background: #007EEA;
        background: var(--theme_primary_hover_color, #007EEA);
    }

    .base-comp-button-type-primary.base-comp-button-disabled {
        color: rgba(255, 255, 255, 0.4);
        color: var(--common_white2_color, rgba(255, 255, 255, 0.4));
        background: #0089ff;
        background: var(--theme_primary1_color, #0089ff);
    }


    .module-bind-mobile .module-comm-page-desc {
        margin-bottom: 50px;
    }

    .module-bind-mobile .module-bind-mobile-input-box {
        margin: 0 auto 12px;
    }

    .module-bind-mobile .module-bind-mobile-op-line {
        position: relative;
        height: 14px;
        margin-top: 16px;
    }

    .module-bind-mobile .module-bind-mobile-op-line .module-bind-mobile-op-item {
        display: block;
        position: relative;
        height: 14px;
        line-height: 14px;
        font-size: 14px;
        color: rgba(23, 26, 29, 0.6);
        color: var(--common_level2_base_color, rgba(23, 26, 29, 0.6));
        cursor: pointer;
        transition: all 0.3s;
    }

    .module-bind-mobile .module-bind-mobile-op-line .module-bind-mobile-op-item:hover {
        color: #0089ff;
        color: var(--theme_primary1_color, #0089ff);
    }

    .base-comp-mobile-box .base-comp-mobile-box-select {
        position: absolute;
        width: 75px;
        height: 48px;
        left: 0;
        top: 0;
        bottom: 0;
    }

    .base-comp-mobile-box .base-comp-mobile-box-select .base-comp-mobile-box-select-value {
        display: block;
        position: absolute;
        height: 16px;
        left: 8px;
        right: 0;
        top: 50%;
        margin-top: -8px;
        line-height: 16px;
        font-size: 15px;
        color: #171a1d;
        color: var(--common_level1_base_color, #171a1d);
        text-align: center;
        border-right: 1px solid rgba(126, 134, 142, 0.16);
        border-right: 1px solid var(--common_line_light_color, rgba(126, 134, 142, 0.16));
    }

    .base-comp-mobile-box .base-comp-mobile-box-select select {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: 0;
        padding: 0;
        opacity: 0.01;
        border: none;
        outline: none;
    }

    .base-comp-mobile-box .base-comp-mobile-box-input {
        display: block;
        position: absolute;
        height: 24px;
        left: 10px;
        right: 48px;
        top: 50%;
        margin-top: -12px;
    }

    .base-comp-mobile-box .base-comp-mobile-box-op {
        position: absolute;
        width: 48px;
        height: 48px;
        right: 0;
        top: 0;
        bottom: 0;
        line-height: 48px;
        font-size: 18px;
        color: rgba(23, 26, 29, 0.24);
        color: var(--common_level4_base_color, rgba(23, 26, 29, 0.24));
        text-align: center;
        cursor: pointer;
    }

    .base-comp-mobile-box .base-comp-mobile-box-op:hover {
        color: #0089ff;
        color: var(--theme_primary1_color, #0089ff);
    }

    .base-comp-input-box {
        position: relative;
        height: 48px;
        padding: 0;
        line-height: 48px;
        font-size: 16px;
        text-align: center;
        border-radius: 8px;
        background: #f2f2f6;
        background: var(--common_bg_color, #f2f2f6);
    }

    .base-comp-input-box input {
        display: block;
        width: 100%;
        height: 24px;
        margin: 0;
        padding: 0;
        line-height: 24px;
        font-size: 15px;
        color: #171a1d;
        color: var(--common_level1_base_color, #171a1d);
        text-align: left;
        background: none;
        outline: none;
        border: none;
        box-shadow: 0 0 0 1000px #f2f2f6 inset !important;
        box-shadow: 0 0 0 1000px var(--common_bg_color, #f2f2f6) inset !important;
    }

    .base-comp-input-box input::-ms-clear,
    .base-comp-input-box input::-ms-reveal {
        display: none;
    }

    .base-comp-input-box input::placeholder {
        color: rgba(23, 26, 29, 0.24);
        color: var(--common_level4_base_color, rgba(23, 26, 29, 0.24));
    }


    .module-verify .module-verify-code-area {
        overflow: hidden;
        position: relative;
        height: 56px;
        margin: 30px 0 10px;
    }

    .module-verify .module-verify-code-area .module-verify-code-container {
        float: left;
        position: relative;
        height: 56px;
        width: 15%;
        margin: 0 0 0 2%;
    }

    .module-verify .module-verify-code-area .module-verify-code-container:first-child {
        margin-left: 0;
    }

    .module-verify .module-verify-code-area .module-verify-code-input {
        position: absolute;
        border: none;
        outline: none;
    }

    .module-verify .module-verify-code-area .module-verify-code-box,
    .module-verify .module-verify-code-area .module-verify-code-input {
        display: block;
        position: absolute;
        width: 100%;
        height: 56px;
        line-height: 56px;
        top: 0;
        left: 0;
        font-size: 20px;
        border-radius: 8px;
        text-align: center;
        font-weight: bold;
        color: #171a1d;
        color: var(--common_level1_base_color, #171a1d);
        background: #f2f2f6;
        background: var(--common_bg_color, #f2f2f6);
    }

    .module-verify .module-verify-op-btn {
        position: relative;
        height: 30px;
        margin: 10px 0 0;
        line-height: 30px;
        font-size: 14px;
        color: #0089ff;
        color: var(--theme_primary1_color, #0089ff);
        text-align: left;
        cursor: pointer;
    }

    .module-verify .module-verify-op-btn:hover {
        opacity: 0.9;
    }

    .module-verify .module-verify-op-btn span {
        margin-right: 3px;
    }

    .base-comp-password-box .base-comp-password-box-input {
        display: block;
        position: absolute;

        .ant-input-affix-wrapper {
            font-size: 18px !important;
            width: 120%;
        }

        // height: 24px;
        // left: 18px;
        // right: 48px;
        // top: 50%;
        // margin-top: -12px;
    }

    .base-comp-password-box .base-comp-password-box-op {
        position: absolute;
        width: 48px;
        height: 48px;
        right: 0;
        top: 0;
        bottom: 0;
        line-height: 48px;
        font-size: 18px;
        color: rgba(23, 26, 29, 0.24);
        color: var(--common_level4_base_color, rgba(23, 26, 29, 0.24));
        text-align: center;
        cursor: pointer;
    }

    .base-comp-password-box .base-comp-password-box-op:hover {
        color: #0089ff;
        color: var(--theme_primary1_color, #0089ff);
    }

    .base-comp-password-box .base-comp-password-box-forgot {
        display: none;
        position: absolute;
        width: 80px;
        height: 48px;
        right: 0;
        top: 0;
        bottom: 0;
        line-height: 48px;
        font-size: 14px;
        color: rgba(23, 26, 29, 0.6);
        color: var(--common_level2_base_color, rgba(23, 26, 29, 0.6));
        text-align: center;
        cursor: pointer;
    }

    .base-comp-password-box .base-comp-password-box-forgot:hover {
        color: #0089ff;
        color: var(--theme_primary1_color, #0089ff);
    }

    .base-comp-password-box .base-comp-password-box-forgot::before {
        content: '';
        display: block;
        position: absolute;
        width: 1px;
        height: 12px;
        left: 0;
        top: 50%;
        margin-top: -6px;
        background: rgba(126, 134, 142, 0.16);
        background: var(--common_line_light_color, rgba(126, 134, 142, 0.16));
    }

    .base-comp-password-box.base-comp-password-box-showforgot .base-comp-password-box-input {
        right: 120px;
    }

    .base-comp-password-box.base-comp-password-box-showforgot .base-comp-password-box-op {
        width: 40px;
        right: 80px;
    }

    .base-comp-password-box.base-comp-password-box-showforgot .base-comp-password-box-forgot {
        display: block;
    }


    .module-confirm {
        position: relative;
        padding-top: 60px;
    }

    .module-confirm .module-confirm-avatar {
        display: block;
        margin: 0 auto;
    }

    .module-confirm .module-confirm-logo {
        display: block;
        width: 48px;
        height: 48px;
        margin: 0 auto;
    }

    .module-confirm .module-confirm-logo.module-confirm-logo-fill {
        line-height: 50px;
        font-size: 36px;
        color: #ffffff;
        color: var(--common_white1_color, #ffffff);
        text-align: center;
        background: #0089ff;
        background: var(--theme_primary1_color, #0089ff);
        border-radius: 8px;
    }

    .module-confirm .module-confirm-title {
        display: block;
        height: 30px;
        margin: 8px auto 0;
        line-height: 30px;
        font-size: 20px;
        color: #171a1d;
        color: var(--common_level1_base_color, #171a1d);
        text-align: center;
        font-weight: bold;
    }

    .module-confirm .module-confirm-desc {
        display: block;
        margin: 60px auto 0;
        line-height: 25px;
        font-size: 14px;
        color: rgba(23, 26, 29, 0.6);
        color: var(--common_level2_base_color, rgba(23, 26, 29, 0.6));
        text-align: center;
    }

    .module-confirm .module-confirm-button {
        display: block;
        margin: 20px auto 0;
    }

    .base-comp-avatar {
        overflow: hidden;
    }

    .base-comp-avatar .base-comp-avatar-inner {
        display: block;
        width: 100%;
        height: 100%;
        margin: 0;
        background-size: cover;
    }

    .module-consent .module-consent-title {
        font-size: 20px;
        color: #171a1d;
        color: var(--common_level1_base_color, #171a1d);
        line-height: 28px;
        margin-bottom: 15px;
        font-weight: bold;
    }

    .module-consent .module-consent-userinfo {
        font-size: 16px;
        height: 32px;
        line-height: 32px;
        color: #171a1d;
        color: var(--common_level1_base_color, #171a1d);
        margin-bottom: 36px;
        font-weight: bold;
    }

    .module-consent .module-consent-userinfo .module-consent-avatar {
        display: inline-block;
        margin-right: 9px;
        position: relative;
        vertical-align: middle;
        top: -2px;
    }

    .module-consent .module-consent-subtitle {
        margin-bottom: 4px;
        line-height: 28px;
        font-size: 16px;
        color: #171a1d;
        color: var(--common_level1_base_color, #171a1d);
        font-weight: bold;
    }

    .module-consent .module-consent-subtitle .module-consent-subtitle-link {
        color: #0089ff;
        color: var(--theme_primary1_color, #0089ff);
        margin-left: 4px;
        cursor: pointer;
    }

    .module-consent .module-consent-auth-list {
        font-size: 14px;
        line-height: 32px;
        list-style-type: none;
        padding: 0;
        margin: 0 0 36px 0;
        color: rgba(23, 26, 29, 0.6);
        color: var(--common_level2_base_color, rgba(23, 26, 29, 0.6));
    }

    .module-consent .module-consent-auth-list .module-consent-auth-list-item {
        padding-left: 11px;
        position: relative;
    }

    .module-consent .module-consent-auth-list .module-consent-auth-list-item::before {
        position: absolute;
        content: ' ';
        border: 2px solid rgba(23, 26, 29, 0.6);
        border: 2px solid var(--common_level2_base_color, rgba(23, 26, 29, 0.6));
        width: 0;
        left: 0;
        top: 15px;
    }

    .module-consent .module-consent-submit-area {
        display: flex;
        justify-content: space-between;
        z-index: 1;
        margin-top: 25px;
        text-align: center;
    }

    .module-consent .module-consent-submit-area .module-consent-submit-reject,
    .module-consent .module-consent-submit-area .module-consent-submit-agree {
        display: inline-block;
        height: 48px;
        line-height: 48px;
        border-radius: 5px 5px;
    }

    .module-consent .module-consent-submit-area .module-consent-submit-reject {
        width: 35%;
        color: #171a1d;
        color: var(--common_level1_base_color, #171a1d);
        background: #FFFFFF;
        background: var(--button_secondary_bg_color, #FFFFFF);
        border: 1px solid rgba(126, 134, 142, 0.16);
        border: 1px solid var(--common_line_light_color, rgba(126, 134, 142, 0.16));
    }

    .module-consent .module-consent-submit-area .module-consent-submit-reject:hover {
        color: #171a1d;
        color: var(--common_level1_base_color, #171a1d);
        background: #f2f2f6;
        background: var(--common_bg_color, #f2f2f6);
        border: 1px solid rgba(126, 134, 142, 0.16);
        border: 1px solid var(--common_line_light_color, rgba(126, 134, 142, 0.16));
    }

    .module-consent .module-consent-submit-area .module-consent-submit-agree {
        width: 60%;
        color: #ffffff;
        color: var(--common_white1_color, #ffffff);
        background: #0089ff;
        background: var(--theme_primary1_color, #0089ff);
    }

    .module-consent .module-consent-submit-area .module-consent-submit-agree:hover {
        color: #ffffff;
        color: var(--common_white1_color, #ffffff);
        background: #007EEA;
        background: var(--theme_primary_hover_color, #007EEA);
    }

    .module-corp-sel {
        margin: 0;
        padding: 0;
    }

    .module-corp-sel .module-corp-sel-list {
        margin: 30px auto 0;
        max-height: 450px;
        padding-bottom: 100px;
        overflow: auto;
    }

    .module-corp-sel .module-corp-sel-list .module-corp-sel-listitem {
        box-sizing: content-box;
        position: relative;
        min-height: 48px;
        margin-top: 12px;
        padding: 10px 44px 10px 70px;
        background: #f2f2f6;
        background: var(--common_bg_color, #f2f2f6);
        border-radius: 8px;
        transition: all 0.3s;
        cursor: pointer;
    }

    .module-corp-sel .module-corp-sel-list .module-corp-sel-listitem:hover {
        transform: translateX(3px);
    }

    .module-corp-sel .module-corp-sel-list .module-corp-sel-listitem:first-child {
        margin-top: 0;
    }

    .module-corp-sel .module-corp-sel-list .module-corp-sel-listitem .module-corp-sel-listitem-avatar {
        overflow: hidden;
        position: absolute;
        width: 48px;
        height: 48px;
        left: 10px;
        top: 10px;
        line-height: 48px;
        font-size: 48px;
        text-align: center;
        border-radius: 8px;
        color: rgba(0, 137, 255, 0.48);
        background: #ffffff;
    }

    .module-corp-sel .module-corp-sel-list .module-corp-sel-listitem table {
        min-height: 48px;
    }

    .module-corp-sel .module-corp-sel-list .module-corp-sel-listitem .module-corp-sel-listitem-title {
        min-height: 48px;
        line-height: 24px;
        font-size: 14px;
        color: #171a1d;
        color: var(--common_level1_base_color, #171a1d);
        text-align: left;
        vertical-align: middle;
    }

    .module-corp-sel .module-corp-sel-list .module-corp-sel-listitem .module-corp-sel-listitem-arrow {
        position: absolute;
        width: 20px;
        height: 20px;
        right: 12px;
        top: 50%;
        margin-top: -10px;
        font-size: 20px;
        color: rgba(23, 26, 29, 0.6);
        color: var(--common_level2_base_color, rgba(23, 26, 29, 0.6));
        line-height: 20px;
        text-align: center;
    }

    .module-corp-sel .module-corp-sel-more {
        overflow: hidden;
        position: relative;
        height: 30px;
        margin-top: 12px;
        line-height: 0;
        font-size: 0;
        color: rgba(23, 26, 29, 0.6);
        color: var(--common_level2_base_color, rgba(23, 26, 29, 0.6));
        text-align: center;
        cursor: pointer;
    }

    .module-corp-sel .module-corp-sel-more:hover {
        color: #0089ff;
        color: var(--theme_primary1_color, #0089ff);
    }

    .module-corp-sel .module-corp-sel-more .module-corp-sel-more-text {
        display: inline-block;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        vertical-align: middle;
    }

    .module-corp-sel .module-corp-sel-more .module-corp-sel-more-arrow {
        display: inline-block;
        height: 12px;
        width: 12px;
        line-height: 12px;
        font-size: 12px;
        vertical-align: middle;
    }

    .module-corp-sel .module-corp-sel-no-corp-icon {
        position: relative;
        width: 180px;
        height: 105px;
        margin: 0 auto 30px;
        background: url('../static/imgs/findOrg.png') center center no-repeat;
        background-size: cover;
    }

    .module-corp-sel .module-corp-sel-no-corp-title {
        position: relative;
        margin: 0 auto 15px;
        line-height: 30px;
        font-size: 24px;
        color: #171a1d;
        color: var(--common_level1_base_color, #171a1d);
        text-align: center;
        font-weight: bold;
    }

    .module-corp-sel .module-corp-sel-no-corp-desc {
        position: relative;
        margin: 0 auto 15px;
        line-height: 30px;
        font-size: 16px;
        color: rgba(23, 26, 29, 0.6);
        color: var(--common_level2_base_color, rgba(23, 26, 29, 0.6));
        text-align: left;
    }

    .module-corp-sel .module-corp-sel-back-login-btn {
        margin: 40px auto 0;
    }

    .module-corp-sel .module-corp-sel-register {
        position: relative;
        height: 25px;
        margin: 20px auto 0;
        line-height: 25px;
        font-size: 14px;
        color: rgba(23, 26, 29, 0.6);
        color: var(--common_level2_base_color, rgba(23, 26, 29, 0.6));
        text-align: center;
        transition: all 0.3s;
        cursor: pointer;
    }

    .module-corp-sel .module-corp-sel-register:hover {
        color: #0089ff;
        color: var(--theme_primary1_color, #0089ff);
    }

    .module-corp-sel-list-blank {
        position: absolute;
        z-index: 2;
        height: 40px;
        left: 0;
        right: 0;
        bottom: 0;
        background: #ffffff;
        background: var(--common_bg_z1_color, #ffffff);
    }


    .module-factor .module-factor-button-area {
        overflow: hidden;
        margin: 60px auto 12px;
    }

    .module-factor .module-factor-button-area .module-factor-op-btn {
        width: 48%;
    }

    .module-factor .module-factor-button-area .module-factor-op-btn.module-factor-op-btn-left {
        float: left;
    }

    .module-factor .module-factor-button-area .module-factor-op-btn.module-factor-op-btn-right {
        float: right;
    }

    .module-factor .module-factor-fail-icon {
        position: relative;
        width: 100px;
        height: 100px;
        margin: 0 auto;
        line-height: 100px;
        font-size: 100px;
        color: #ff5219;
        color: var(--theme_danger1_color, #ff5219);
        text-align: center;
    }

    .module-factor .module-factor-fail-title {
        position: relative;
        margin-top: 16px;
        line-height: 24px;
        font-size: 18px;
        color: #171a1d;
        color: var(--common_level1_base_color, #171a1d);
        text-align: center;
    }

    .module-factor .module-factor-fail-desc {
        position: relative;
        margin-top: 8px;
        line-height: 24px;
        font-size: 14px;
        color: rgba(23, 26, 29, 0.6);
        color: var(--common_level2_base_color, rgba(23, 26, 29, 0.6));
        text-align: center;
    }

    .module-factor .module-factor-fail-btn {
        margin-top: 40px;
    }

    .module-info-tip {
        padding: 30px 0 0;
    }

    .module-info-tip .module-info-tip-img {
        display: block;
        position: relative;
        width: 180px;
        height: 180px;
        margin: 0 auto;
    }

    .module-info-tip .module-info-tip-title {
        position: relative;
        margin-top: 16px;
        line-height: 24px;
        font-size: 18px;
        color: #171a1d;
        color: var(--common_level1_base_color, #171a1d);
        text-align: center;
        font-weight: bold;
    }

    .module-info-tip .module-info-tip-desc {
        position: relative;
        margin-top: 8px;
        line-height: 24px;
        font-size: 14px;
        color: rgba(23, 26, 29, 0.6);
        color: var(--common_level2_base_color, rgba(23, 26, 29, 0.6));
        text-align: center;
    }

    .module-info-tip .module-info-tip-btn {
        margin-top: 40px;
    }

    .module-forget-password .module-forget-password-input-box {
        margin: 20px auto 12px;
    }

    .module-forget-password .module-forget-password-op-line {
        position: relative;
        height: 14px;
        margin-top: 16px;
    }

    .module-forget-password .module-forget-password-op-line .module-forget-password-op-item {
        display: block;
        position: relative;
        height: 14px;
        line-height: 14px;
        font-size: 14px;
        color: rgba(23, 26, 29, 0.6);
        color: var(--common_level2_base_color, rgba(23, 26, 29, 0.6));
        cursor: pointer;
        transition: all 0.3s;
    }

    .module-forget-password .module-forget-password-op-line .module-forget-password-op-item:hover {
        color: #0089ff;
        color: var(--theme_primary1_color, #0089ff);
    }

    .base-comp-text-box .base-comp-text-box-input {
        display: block;
        position: absolute;
        height: 24px;
        left: 18px;
        right: 48px;
        top: 50%;
        margin-top: -12px;
    }

    .base-comp-text-box .base-comp-text-box-op {
        position: absolute;
        width: 48px;
        height: 48px;
        right: 0;
        top: 0;
        bottom: 0;
        line-height: 48px;
        font-size: 18px;
        color: rgba(23, 26, 29, 0.24);
        color: var(--common_level4_base_color, rgba(23, 26, 29, 0.24));
        text-align: center;
        cursor: pointer;
    }

    .base-comp-text-box .base-comp-text-box-op:hover {
        color: #0089ff;
        color: var(--theme_primary1_color, #0089ff);
    }

    .module-org-code {
        margin: 0;
        padding: 0;
    }

    .module-org-code .module-org-code-input-box {
        margin: 60px auto 12px;
    }

    .module-org-code .module-org-code-op-line {
        position: relative;
        height: 14px;
        margin-top: 16px;
    }

    .module-org-code .module-org-code-op-line .module-org-code-op-item-remember {
        float: left;
    }

    .module-org-code .module-org-code-op-line .module-org-code-op-item-tip {
        display: block;
        float: right;
        position: relative;
        height: 14px;
        line-height: 14px;
        font-size: 14px;
        color: rgba(23, 26, 29, 0.6);
        color: var(--common_level2_base_color, rgba(23, 26, 29, 0.6));
        cursor: pointer;
        transition: all 0.3s;
    }

    .module-org-code .module-org-code-op-line .module-org-code-op-item-tip:hover {
        color: #0089ff;
        color: var(--theme_primary1_color, #0089ff);
    }

    .module-footer {
        position: absolute;
        z-index: 20;
        height: 20px;
        left: 0;
        right: 0;
        bottom: 30px;
        line-height: 20px;
        font-size: 0;
        text-align: center;
    }

    .module-footer .module-footer-item {
        display: inline-block;
        position: relative;
        height: 20px;
        line-height: 20px;
        font-size: 14px;
        vertical-align: middle;
        color: rgba(23, 26, 29, 0.6);
        color: var(--common_level2_base_color, rgba(23, 26, 29, 0.6));
        transition: all 0.3s;
        cursor: pointer;
    }

    .module-footer .module-footer-item::before {
        content: '';
        display: inline-block;
        width: 1px;
        height: 10px;
        margin: 0 12px;
        background: rgba(126, 134, 142, 0.16);
        background: var(--common_line_light_color, rgba(126, 134, 142, 0.16));
        vertical-align: middle;
    }

    .module-footer .module-footer-item:first-child::before {
        display: none;
    }

    .module-footer .module-footer-item:hover {
        color: #0089ff;
        color: var(--theme_primary1_color, #0089ff);
    }

    .module-footer .module-footer-language {
        padding-right: 16px;
    }

    .module-footer .module-footer-language .dingtalk-login-iconfont {
        display: block;
        position: absolute;
        height: 20px;
        right: 0;
        top: 50%;
        margin-top: -10px;
        line-height: 20px;
        font-size: 16px;
        text-align: right;
    }

    .module-footer .module-footer-language .module-footer-select {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        opacity: 0.01;
    }


    .base-comp-check-box {
        display: block;
        position: relative;
        padding-left: 0px;
        line-height: 14px;
        font-size: 14px;
        color: rgba(23, 26, 29, 0.6);
        color: var(--common_level2_base_color, rgba(23, 26, 29, 0.6));
        cursor: pointer;
        transition: all 0.3s;
    }

    .base-comp-check-box:hover {
        color: #0089ff;
        color: var(--theme_primary1_color, #0089ff);
    }

    .base-comp-check-box .base-comp-check-box-rememberme-box {
        box-sizing: border-box;
        position: absolute;
        width: 14px;
        height: 14px;
        left: 0;
        top: 0;
        line-height: 14px;
        font-size: 16px;
        color: rgba(23, 26, 29, 0.6);
        color: var(--common_level2_base_color, rgba(23, 26, 29, 0.6));
        text-align: center;
    }

    .base-comp-check-box .base-comp-check-box-rememberme-box.active {
        color: #0089ff;
        color: var(--theme_primary1_color, #0089ff);
    }

    .module-pass-login .module-pass-app-info {
        margin-bottom: 20px;
    }

    .module-pass-login .module-pass-app-info .module-pass-login-logo {
        display: block;
        width: 48px;
        height: 48px;
        margin: 0 auto;
    }

    .module-pass-login .module-pass-app-info .module-pass-login-logo.module-pass-login-logo-fill {
        line-height: 50px;
        font-size: 36px;
        color: #ffffff;
        color: var(--common_white1_color, #ffffff);
        text-align: center;
        background: #0089ff;
        background: var(--theme_primary1_color, #0089ff);
        border-radius: 8px;
    }

    .module-pass-login .module-pass-app-info .module-pass-login-title {
        height: 30px;
        margin: 10px auto 0;
        line-height: 30px;
        font-size: 20px;
        color: #171a1d;
        color: var(--common_level1_base_color, #171a1d);
        text-align: center;
        font-weight: bold;
    }

    .module-pass-login .module-pass-app-info .module-pass-login-desc {
        height: 30px;
        margin: 0 auto;
        line-height: 30px;
        font-size: 16px;
        color: #171a1d;
        color: var(--common_level1_base_color, #171a1d);
        text-align: center;
    }

    .module-pass-login .module-pass-login-type-tab-area {
        min-height: 1px;
        margin: 0 auto 25px;
        line-height: 0;
        font-size: 0;
        text-align: left;
    }

    .module-pass-login .module-pass-login-type-tab-area .module-pass-login-type-tab-item {
        display: inline-block;
        position: relative;
        height: 45px;
        margin-right: 16px;
        padding: 0 2px;
        line-height: 45px;
        font-size: 16px;
        color: rgba(23, 26, 29, 0.6);
        color: var(--common_level2_base_color, rgba(23, 26, 29, 0.6));
        text-align: center;
        cursor: pointer;
        transition: all 0.5s;
    }

    .module-pass-login .module-pass-login-type-tab-area .module-pass-login-type-tab-item::after {
        content: '';
        display: block;
        position: absolute;
        width: 20px;
        height: 3px;
        bottom: 0;
        left: 50%;
        margin-left: -10px;
        background: #171a1d;
        background: var(--common_level1_base_color, #171a1d);
        border-radius: 2px;
        opacity: 0;
        transform: scaleX(0);
        transition: all 0.5s;
    }

    .module-pass-login .module-pass-login-type-tab-area .module-pass-login-type-tab-item.module-pass-login-type-tab-item-active {
        color: #171a1d;
        color: var(--common_level1_base_color, #171a1d);
    }

    .module-pass-login .module-pass-login-type-tab-area .module-pass-login-type-tab-item.module-pass-login-type-tab-item-active::after {
        opacity: 1;
        transform: scaleX(1);
    }

    .module-pass-login .module-pass-login-form-area {
        display: none;
    }

    .module-pass-login .module-pass-login-form-area.module-pass-login-form-area-active {
        display: block;
    }

    .module-pass-login .module-pass-login-input-box {
        margin: 0 auto 12px;
        opacity: 1;
        transition: all 0.3s;
    }

    .module-pass-login .module-pass-login-input-box.module-pass-login-input-box-hidden {
        height: 0;
        opacity: 0;
        margin: 0 auto;
    }

    .module-pass-login .module-pass-login-op-line {
        position: relative;
        height: 14px;
        margin-top: 16px;
    }

    .module-pass-login .module-pass-login-op-line .module-pass-login-op-item {
        display: block;
        position: relative;
        height: 14px;
        line-height: 14px;
        font-size: 14px;
        color: rgba(23, 26, 29, 0.6);
        color: var(--common_level2_base_color, rgba(23, 26, 29, 0.6));
        cursor: pointer;
        transition: all 0.3s;
    }

    .module-pass-login .module-pass-login-op-line .module-pass-login-op-item:hover {
        color: #0089ff;
        color: var(--theme_primary1_color, #0089ff);
    }

    .module-pass-login .module-pass-login-op-line .module-pass-login-op-item-line {
        float: right;
        width: 1px;
        height: 14px;
        margin: 0 8px;
        background: rgba(126, 134, 142, 0.16);
        background: var(--common_line_light_color, rgba(126, 134, 142, 0.16));
    }

    .module-pass-login .module-pass-login-op-line .module-pass-login-op-item-rememberme {
        float: left;
    }

    .module-pass-login .module-pass-login-op-line .account-login-item {
        float: right;
        display: inline-block;
        position: relative;
        height: 20px;
        line-height: 20px;
        font-size: 14px;
        vertical-align: middle;
        color: rgba(23, 26, 29, 0.6);
        color: var(--common_level2_base_color, rgba(23, 26, 29, 0.6));
        transition: all 0.3s;
        cursor: pointer;


        &:hover {
            color: #0089ff;
            color: var(--theme_primary1_color, #0089ff);
        }
    }

    .module-pass-login .module-pass-login-op-line .module-pass-login-op-item-loginverify {
        float: right;
    }

    .module-pass-login .module-pass-login-op-line .module-pass-login-op-item-forgetpass {
        float: right;
    }


    .module-qrcode {
        opacity: 1;
        transition: all 0.5s;
    }

    .module-qrcode.module-qrcode-visible {
        opacity: 1;
    }

    .module-qrcode .module-qrcode-login-logo {
        display: block;
        width: 48px;
        height: 48px;
        margin: 0 auto;
    }

    .module-qrcode .module-qrcode-login-logo.module-qrcode-login-logo-fill {
        line-height: 50px;
        font-size: 36px;
        color: #ffffff;
        color: var(--common_white1_color, #ffffff);
        text-align: center;
        background: #0089ff;
        background: var(--theme_primary1_color, #0089ff);
        border-radius: 8px;
    }

    .module-qrcode .module-qrcode-login-title {
        height: 30px;
        margin: 10px auto 0;
        line-height: 30px;
        font-size: 20px;
        color: #171a1d;
        color: var(--common_level1_base_color, #171a1d);
        text-align: center;
        font-weight: bold;
    }

    .module-qrcode .module-qrcode-login-desc {
        height: 30px;
        margin: 0 auto;
        line-height: 30px;
        font-size: 16px;
        color: #171a1d;
        color: var(--common_level1_base_color, #171a1d);
        text-align: center;
    }

    .module-qrcode .module-qrcode-area {
        position: relative;
        width: 280px;
        height: 280px;
        margin: 10px auto 0;
    }

    .module-qrcode .module-qrcode-area .module-qrcode-code {
        box-sizing: content-box;
        position: absolute;
        width: 200px;
        height: 200px;
        left: 50%;
        top: 50%;
        margin-left: -110px;
        margin-top: -110px;
        padding: 10px;
        background: #ffffff;
        background: var(--common_white1_color, #ffffff);
    }

    .module-qrcode .module-qrcode-area .module-qrcode-code .module-qrcode-expired-cover {
        display: none;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: rgba(255, 255, 255, 0.6);
    }

    .module-qrcode .module-qrcode-area .module-qrcode-code .module-qrcode-expired-cover span {
        overflow: hidden;
        display: block;
        position: absolute;
        width: 40px;
        height: 40px;
        left: 50%;
        top: 50%;
        margin: -20px 0 0 -20px;
        line-height: 40px;
        font-size: 36px;
        color: #000000;
        color: var(--common_black1_color, #000000);
        text-align: center;
        transition: all 0.5s;
        border-radius: 40px;
        background: #ffffff;
        background: var(--common_white1_color, #ffffff);
    }

    .module-qrcode .module-qrcode-area .module-qrcode-op-line {
        position: absolute;
        width: 220px;
        height: 14px;
        left: 50%;
        top: 50%;
        margin-left: -110px;
        margin-top: 120px;
        line-height: 0;
        font-size: 0;
        text-align: center;
    }

    .module-qrcode .module-qrcode-area .module-qrcode-op-line .module-qrcode-op-item {
        display: inline-block;
    }

    .module-qrcode .module-qrcode-area .module-qrcode-op-line .module-qrcode-op-item.module-qrcode-op-item-disabled {
        opacity: 0.6;
    }

    .module-qrcode .module-qrcode-area .module-qrcode-op-line .module-qrcode-op-item.module-qrcode-op-item-disabled:hover {
        color: rgba(23, 26, 29, 0.6);
        color: var(--common_level2_base_color, rgba(23, 26, 29, 0.6));
    }

    .module-qrcode .module-qrcode-area .module-qrcode-expired-tip {
        display: none;
        position: absolute;
        width: 220px;
        height: 14px;
        left: 50%;
        top: 50%;
        margin-left: -110px;
        margin-top: 110px;
        font-size: 12px;
        color: rgba(23, 26, 29, 0.4);
        color: var(--common_level3_base_color, rgba(23, 26, 29, 0.4));
        text-align: center;
    }

    .module-qrcode .module-qrcode-area.module-qrcode-area-expired:hover .module-qrcode-expired-cover span {
        transform: rotate(360deg);
    }

    .module-qrcode .module-qrcode-area.module-qrcode-area-expired .module-qrcode-op-line {
        display: none;
    }

    .module-qrcode .module-qrcode-area.module-qrcode-area-expired .module-qrcode-expired-tip,
    .module-qrcode .module-qrcode-area.module-qrcode-area-expired .module-qrcode-expired-cover {
        cursor: pointer;
        display: block;
    }

    .module-qrcode-wrapper-iframe {
        position: absolute !important;
        left: 0 !important;
        right: 0 !important;
        top: 0 !important;
        bottom: 0 !important;
        max-width: 10000px !important;
        width: auto !important;
        height: auto !important;
        margin: 0 !important;
        padding: 0 !important;
    }

    .module-qrcode-wrapper-iframe .module-qrcode-area {
        position: absolute !important;
        left: 50%;
        top: 50%;
        margin-left: -140px !important;
        margin-top: -140px !important;
    }

    .module-qrcode-wrapper-iframe .module-qrcode-login-logo,
    .module-qrcode-wrapper-iframe .module-qrcode-login-title,
    .module-qrcode-wrapper-iframe .module-qrcode-login-desc,
    .module-qrcode-wrapper-iframe .module-qrcode-op-item-rememberme {
        display: none !important;
    }


    .module-qrscan .module-qrscan-computer {
        display: block;
        position: relative;
        width: 180px;
        margin: 0 auto;
    }

    .module-qrscan .module-qrscan-login-tip {
        margin-top: 20px;
        padding: 0 32px;
        line-height: 25px;
        font-size: 17px;
        color: #171a1d;
        color: var(--common_level1_base_color, #171a1d);
        text-align: center;
    }

    .module-qrscan .module-qrscan-login-btn,
    .module-qrscan .module-qrscan-login-cancel {
        position: absolute;
        width: 190px;
        height: 45px;
        left: 50%;
        margin-left: -95px;
        line-height: 45px;
        text-align: center;
    }

    .module-qrscan .module-qrscan-login-btn {
        bottom: 110px;
        font-size: 17px;
        color: #ffffff;
        color: var(--common_white1_color, #ffffff);
        background: #0089ff;
        background: var(--theme_primary1_color, #0089ff);
    }

    .module-qrscan .module-qrscan-login-btn.module-qrscan-login-btn-disabled {
        opacity: 0.4;
    }

    .module-qrscan .module-qrscan-login-cancel {
        bottom: 50px;
        font-size: 15px;
        color: rgba(23, 26, 29, 0.6);
        color: var(--common_level2_base_color, rgba(23, 26, 29, 0.6));
    }


    .module-register .module-register-input-box {
        margin: 60px auto 12px;
    }

    .module-register .module-register-op-line {
        position: relative;
        margin-top: 16px;
        // padding-left: 22px;
    }

    .module-register .module-register-op-line .module-register-op-item-agree-box {
        box-sizing: border-box;
        position: absolute;
        width: 14px;
        height: 14px;
        left: 0;
        top: 3px;
        line-height: 14px;
        font-size: 16px;
        color: rgba(23, 26, 29, 0.6);
        color: var(--common_level2_base_color, rgba(23, 26, 29, 0.6));
        text-align: center;
    }

    .module-register .module-register-op-line .module-register-op-item-agree-box.active {
        color: #0089ff;
        color: var(--theme_primary1_color, #0089ff);
    }

    .module-register .module-register-op-line .module-register-op-item {
        display: block;
        position: relative;
        line-height: 20px;
        font-size: 14px;
        color: rgba(23, 26, 29, 0.6);
        color: var(--common_level2_base_color, rgba(23, 26, 29, 0.6));
    }

    .module-register .module-register-op-line .module-register-op-item span {
        display: inline-block;
        // margin-right: 5px;

        .ant-checkbox-checked::after {
            border: none !important;
        }


    }

    .module-register .module-register-op-line .module-register-op-item span.module-register-op-item-link {
        color: #0089ff;
        color: var(--theme_primary1_color, #0089ff);
        cursor: pointer;
        margin-right: 5px;
    }


    .module-sso-login .module-sso-login-logo {
        display: block;
        width: 48px;
        height: 48px;
        margin: 0 auto;
    }

    .module-sso-login .module-sso-login-logo.module-sso-login-logo-fill {
        line-height: 50px;
        font-size: 36px;
        color: #ffffff;
        color: var(--common_white1_color, #ffffff);
        text-align: center;
        background: #0089ff;
        background: var(--theme_primary1_color, #0089ff);
        border-radius: 8px;
    }

    .module-sso-login .module-sso-login-title {
        height: 30px;
        margin: 10px auto 50px;
        line-height: 30px;
        font-size: 20px;
        color: #171a1d;
        color: var(--common_level1_base_color, #171a1d);
        text-align: center;
        font-weight: bold;
    }

    .module-sso-login .module-sso-login-input-box {
        margin: 0 auto 12px;
    }

    .module-sso-login .module-sso-login-op-line {
        position: relative;
        height: 14px;
        margin-top: 16px;
    }

    .module-sso-login .module-sso-login-op-line .module-sso-login-op-item {
        display: block;
        position: relative;
        height: 14px;
        line-height: 14px;
        font-size: 14px;
        color: rgba(23, 26, 29, 0.6);
        color: var(--common_level2_base_color, rgba(23, 26, 29, 0.6));
        cursor: pointer;
        transition: all 0.3s;
    }

    .module-sso-login .module-sso-login-op-line .module-sso-login-op-item:hover {
        color: #0089ff;
        color: var(--theme_primary1_color, #0089ff);
    }

    .module-sso-login .module-sso-login-op-line .module-sso-login-op-item-rememberme {
        float: left;
    }

    .base-comp-switcher img {
        display: block;
        width: 100%;
        height: 100%;
        opacity: 0.8;
        transition: all 0.3s;
    }

    .base-comp-switcher img:hover {
        opacity: 1;
    }

    .module-toast {
        display: inline-block;
        position: relative;
        padding: 12px 16px 12px 43px;
        line-height: 20px;
        font-size: 14px;
        color: #fff;
        text-align: center;
        background: rgba(69, 72, 74, 0.9);
        border-radius: 5px;
    }

    .module-toast .module-toast-icon {
        position: absolute;
        width: 20px;
        height: 20px;
        left: 16px;
        top: 50%;
        margin-top: -10px;
        line-height: 20px;
        font-size: 20px;
        text-align: left;
    }

    .module-change-password {
        .module-change-password-input-box {
            margin-top: 30px;
            margin-bottom: 30px;

            .ant-input-affix-wrapper {
                width: 150%;
            }
        }
    }
    .appUnValid{
        margin-top: 20%;
    }
}